import React, { Component } from 'react'
import cn from 'clsx'
import FullscreenImage from '@components/FullscreenImage'
import TourBgImage from '@images/concerts-bg.jpg'
import Layout from '@layout'

class TourPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
    }
    this.onImageLoaded = this.onImageLoaded.bind(this)
  }

  componentDidMount() {
    if (window.SongkickWidget) {
      this.widget = new window.SongkickWidget.Injector()
      this.widget.loadIFrame()
      console.log(this.widget)
    }
  }

  onImageLoaded() {
    this.setState({ loaded: true })
  }

  render() {
    const pageClass = cn('tourpage', { loaded: this.state.loaded })
    return (
      <Layout>
      <div className={pageClass}>
        <FullscreenImage image={TourBgImage} loaded={this.onImageLoaded} />
        <div className="container">
          <div className="songkick-wrapper">
            <a
              href="http://www.songkick.com/artists/9220259"
              className="songkick-widget"
              data-theme="dark"
              data-track-button="on"
              data-detect-style="true"
              data-font-color="#ffffff"
              data-background-color="transparent"
            >
              The Human Nature tour dates
            </a>
          </div>
        </div>
      </div>
      </Layout>
    )
  }
}

export default TourPage
